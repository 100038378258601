<!--  -->
<template>
<div class=''>
  <div class="pageTop">养老院成员管理</div>
  <!-- <button @click="$router.push({ name: 'examplePage3' })">跳转</button> -->
  <el-divider></el-divider>

  <el-input
      style="width: 500px"
      placeholder="请输入内容"
      v-model="search"
      clearable>
  </el-input>

  <el-table
      :data="tableData.filter(data => !search || data.userAcc.toLowerCase().includes(search.toLowerCase()))"
      style="width: 100%">
    <el-table-column
        label="用户编号"
        prop="userNum">
    </el-table-column>
    <el-table-column
        label="用户账号"
        prop="userAcc">
    </el-table-column>
    <el-table-column
        label="用户姓名"
        prop="userName">
    </el-table-column>

    <el-table-column
        prop="userInfo"
        label="个人信息">
<!--      <template v-slot:="scope1">
        <el-link href=scope1.row.url target="_blank">
          查看
        </el-link>
      </template>-->
<!--      <el-button  size="mini" @click="drawer1 = true" type="primary" style="margin-left: 16px;">
        查看
      </el-button>
      <el-drawer
          title="健康小屋：用户个人信息" style="font-size: 30px"
          :visible.sync="drawer1"
          size="75%">
        <el-descriptions class="margin-top"  :column="3" :size="size" border>

          <el-descriptions-item><template slot="label">用户名</template>王小虎</el-descriptions-item>
          <el-descriptions-item><template slot="label">真实姓名</template>王大虎</el-descriptions-item>
          <el-descriptions-item><template slot="label">性别</template>男</el-descriptions-item>
          <el-descriptions-item><template slot="label">年龄</template>23</el-descriptions-item>
          <el-descriptions-item><template slot="label">出生日期</template>  </el-descriptions-item>
          <el-descriptions-item><template slot="label">手机号码</template>11451419198</el-descriptions-item>
          <el-descriptions-item><template slot="label">邮箱</template>  </el-descriptions-item>

        </el-descriptions>

        <el-button style="margin-top: 20px;margin-left: 15px" size="mini" @click="$router.push({ name: 'privateMessage1' })" type="primary">
          查看详情
        </el-button>

      </el-drawer>-->

      <template v-slot="scope1">
<!--        <el-button  size="mini" @click="drawer1 = true" type="primary" style="margin-left: 16px;">
          查看
        </el-button>
        <el-drawer
            title="健康小屋：用户个人信息" style="font-size: 30px"
            :visible.sync="drawer1"
            size="75%">
          <el-descriptions class="margin-top"  :column="3" :size="size" border>
            <el-descriptions-item label="用户名">
              {{ scope1.row.userAcc}}</el-descriptions-item>
            <el-descriptions-item><template slot="label">真实姓名</template>
              {{ scope1.row.userName}}</el-descriptions-item>
            <el-descriptions-item><template slot="label">性别</template>
              {{ scope1.row.userGender}}</el-descriptions-item>
          </el-descriptions>
        </el-drawer>-->
        <el-popover
            title="养老院：用户个人信息"
            placement="right"
            trigger="click"
        width="auto">
          <el-descriptions class="margin-top"  :column="3" :size="medium" border>
            <el-descriptions-item label="用户名">
              {{ scope1.row.userAcc}}</el-descriptions-item>

            <el-descriptions-item><template slot="label">真实姓名</template>
              {{ scope1.row.userName}}</el-descriptions-item>

            <el-descriptions-item><template slot="label">性别</template>
              {{ scope1.row.userGender}}</el-descriptions-item>

            <el-descriptions-item label="年龄">
              {{ scope1.row.userAge}}</el-descriptions-item>

            <el-descriptions-item label="出生日期">
              {{ scope1.row.userBirth}}</el-descriptions-item>

            <el-descriptions-item label="民族">
              {{ scope1.row.userEthnic}}</el-descriptions-item>

            <el-descriptions-item label="籍贯">
              {{ scope1.row.userHomeTown}}</el-descriptions-item>

            <el-descriptions-item label="文化程度">
              {{ scope1.row.userEdu}}</el-descriptions-item>

            <el-descriptions-item label="政治面貌">
              {{ scope1.row.userPolitical}}</el-descriptions-item>

            <el-descriptions-item label="手机号码">
              {{ scope1.row.userPhone}}</el-descriptions-item>

            <el-descriptions-item label="邮箱">
              {{ scope1.row.userMail}}</el-descriptions-item>

            <el-descriptions-item label="身份证号">
              {{ scope1.row.userIDcard}}</el-descriptions-item>

            <el-descriptions-item label="居住地">
              {{ scope1.row.userResidence}}</el-descriptions-item>

            <el-descriptions-item label="户籍属性">
              {{ scope1.row.userRegisterType}}</el-descriptions-item>

            <el-descriptions-item label="户主">
              {{ scope1.row.userHouseOwner}}</el-descriptions-item>

            <el-descriptions-item label="户号">
              {{ scope1.row.userHouseID}}</el-descriptions-item>

            <el-descriptions-item label="身高（cm）">
              {{ scope1.row. userHeight}}</el-descriptions-item>

            <el-descriptions-item label="体重（kg）">
              {{ scope1.row. userWeight}}</el-descriptions-item>

            <el-descriptions-item label="血型">
              {{ scope1.row. userBlood}}</el-descriptions-item>

            <el-descriptions-item label="婚姻">
              {{ scope1.row.userMarriage}}</el-descriptions-item>

            <el-descriptions-item label="职业">
              {{ scope1.row.userProfession}}</el-descriptions-item>

            <el-descriptions-item label="工作单位">
              {{ scope1.row.userEmployer}}</el-descriptions-item>

            <el-descriptions-item label="健康状态自我评估">
              {{ scope1.row.userSelfHealth}}</el-descriptions-item>

            <el-descriptions-item label="心理状况">
              {{ scope1.row. userMentalHealth}}</el-descriptions-item>

            <el-descriptions-item label="过敏药物史">
              {{ scope1.row.userAllergy}}</el-descriptions-item>

            <el-descriptions-item label="既往病史：重症疾病">
              {{ scope1.row.userSevereDisease}}</el-descriptions-item>

            <el-descriptions-item label="既往病史：慢性疾病">
              {{ scope1.row.userChronicDisease}}</el-descriptions-item>

            <el-descriptions-item label="既往病史：传染疾病">
              {{ scope1.row.userInfectiousDisease}}</el-descriptions-item>

            <el-descriptions-item label="既往病史：家族遗传疾病">
              {{ scope1.row.userGeneticDisease}}</el-descriptions-item>

            <el-descriptions-item label="既往病史：地方性疾病">
              {{ scope1.row.userEndemicDisease}}</el-descriptions-item>

            <el-descriptions-item label="既往病史：疫源性疾病">
              {{ scope1.row.userPlague}}</el-descriptions-item>

            <el-descriptions-item label="既往病史：其他少见疾病">
              {{ scope1.row.userRareDisease}}</el-descriptions-item>

            <el-descriptions-item label="既往病史：食物过敏史">
              {{ scope1.row.userFoodAllergy}}</el-descriptions-item>

            <el-descriptions-item label="既往病史：输血史">
              {{ scope1.row. userBloodTransfusion}}</el-descriptions-item>

            <el-descriptions-item label="既往手术史1">
              {{ scope1.row.userSurgicalHistory1}}</el-descriptions-item>

            <el-descriptions-item label="既往手术史2">
              {{ scope1.row.userSurgicalHistory2}}</el-descriptions-item>

            <el-descriptions-item label="既往手术史3">
              {{ scope1.row.userSurgicalHistory3}}</el-descriptions-item>

            <el-descriptions-item label="家族病史：父亲">
              {{ scope1.row. userFatherDisease}}</el-descriptions-item>

            <el-descriptions-item label="家族病史：母亲">
              {{ scope1.row. userMotherDisease}}</el-descriptions-item>

            <el-descriptions-item label="家族病史：兄弟姐妹">
              {{ scope1.row.userBroSisDisease}}</el-descriptions-item>

            <el-descriptions-item label="家族病史：子女">
              {{ scope1.row.userOffspringDisease}}</el-descriptions-item>

            <el-descriptions-item label="锻炼频率">
              {{ scope1.row.userExerciseFrequency}}</el-descriptions-item>

            <el-descriptions-item label="锻炼方式">
              {{ scope1.row.userExerciseWay}}</el-descriptions-item>

            <el-descriptions-item label="锻炼时间">
              {{ scope1.row.userExerciseTime}}</el-descriptions-item>

            <el-descriptions-item label="饮食习惯">
              {{ scope1.row.userEatingHabits}}</el-descriptions-item>

            <el-descriptions-item label="吸烟情况">
              {{ scope1.row.userSmoking}}</el-descriptions-item>

            <el-descriptions-item label="吸烟量">
              {{ scope1.row. userSmokingAmount}}</el-descriptions-item>

            <el-descriptions-item label="饮酒情况">
              {{ scope1.row.userDrinking}}</el-descriptions-item>

            <el-descriptions-item label="饮酒量">
              {{ scope1.row.userDrinkingAmount}}</el-descriptions-item>

            <el-descriptions-item label="饮酒频率">
              {{ scope1.row.userDrinkingFrequency}}</el-descriptions-item>

            <el-descriptions-item label="睡眠情况">
              {{ scope1.row.userSleeping}}</el-descriptions-item>

            <el-descriptions-item label="睡眠时间（小时）">
              {{ scope1.row.userSleepingAmount}}</el-descriptions-item>

            <el-descriptions-item label="职业病危害因素接触史">
              {{ scope1.row.userOccupationalDiseaseRisk}}</el-descriptions-item>

            <el-descriptions-item label="有无防护措施">
              {{ scope1.row.userRiskProtectiveMeasures}}</el-descriptions-item>


          </el-descriptions>

          <div style="text-align: center; margin-top: 10px">
            <el-button type="primary" size="mini">查看详情</el-button>
          </div>
          <el-button slot="reference" size="mini" type="primary">查看</el-button>
        </el-popover>
      </template>


    </el-table-column>

    <el-table-column
        label="体检报告">
      <template v-slot="scope2">
      <el-popover
          title="心狗体检报告"
          placement="right"
          trigger="click"
          width="auto">
        <el-descriptions class="margin-top"  :column="5" :size="medium" border>
          <el-descriptions-item><template slot="label">真实姓名</template>
            {{ scope2.row.userName}}</el-descriptions-item>

          <el-descriptions-item><template slot="label">性别</template>
            {{ scope2.row.userGender}}</el-descriptions-item>

          <el-descriptions-item label="年龄">
            {{ scope2.row.userAge}}</el-descriptions-item>

          <el-descriptions-item label="身高（cm）">
            {{ scope2.row. userHeight}}</el-descriptions-item>

          <el-descriptions-item label="体重（kg）">
            {{ scope2.row. userWeight}}</el-descriptions-item>

        </el-descriptions>

        <el-divider></el-divider>

        <div style="text-align: center">心电检测结果</div>
        <el-table
            :data="scope2.row.InTableData"
            style="width: 100%">

          <el-table-column
              prop="ECGTestNumber"
              label="编号">
          </el-table-column>

          <el-table-column
              prop="TestItem"
              label="检测项目">
          </el-table-column>

          <el-table-column
              prop="TestSuspicion"
              label="疑似度（%）">
          </el-table-column>

          <el-table-column
              prop="TestConclusion"
              label="结论">
          </el-table-column>

        </el-table>

        <div style="text-align: center; margin-top: 10px">
          <el-button type="primary" size="mini">查看更多</el-button>
        </div>
        <el-button slot="reference" size="mini" type="primary">查看</el-button>
      </el-popover>
      </template>
<!--        prop="userTest"-->
<!--      <template v-slot:="scope2">-->
<!--        <el-link href="scope2.row.url" target="_blank">
          查看
        </el-link>-->
<!--        <el-button  size="mini" @click="drawer2 = true" type="primary" style="margin-left: 16px;">
          查看
        </el-button>
      <el-drawer
          title="健康小屋：用户体检报告" style="font-size: 30px"
          :visible.sync="drawer2"
          size="75%">
        <el-descriptions class="margin-top"  :column="5" :size="size" border>
          <el-descriptions-item><template slot="label">真实姓名</template>王大虎</el-descriptions-item>
          <el-descriptions-item><template slot="label">性别</template>男</el-descriptions-item>
          <el-descriptions-item><template slot="label">年龄</template>23</el-descriptions-item>
          <el-descriptions-item><template slot="label">身高</template>170cm</el-descriptions-item>
          <el-descriptions-item><template slot="label">体重</template>60kg</el-descriptions-item>
        </el-descriptions>
        <el-divider></el-divider>
        <div style="font-size: 20px">1.心电检测结果</div>
        <template>
        <el-table title="1.心电检测结果"
            :data="ECGTableData"
          row-class-name="tableRowClassName">
          <el-table-column
              prop="ECG001"
              label="编号"
              >
          </el-table-column>
          <el-table-column
              prop="ECG002"
              label="检测项目"
              >
          </el-table-column>
          <el-table-column
              prop="ECG003"
              label="疑似度"
              >
          </el-table-column>
          <el-table-column
              prop="ECG004"
              label="结论"
              >
          </el-table-column>
        </el-table>
        </template>

        <el-button style="margin-top: 20px;margin-left: 15px" size="mini" @click="$router.push({ name: 'privateMessage1' })" type="primary">
          查看详情
        </el-button>

      </el-drawer>-->

    </el-table-column>

    <el-table-column
        label="健康信息"
        prop="userHeal">
      <template v-slot="scope3">
        <el-popover

            placement="right"
            trigger="click"
            width="auto">
          <div style="text-align: center;font-size: 20px">请选择查询项目</div>
<!--          <el-button  size="mini" @click="$router.push({ name: 'healthDetailPage1' })" type="text" style="margin-left: 25px;font-size: 20px">
            心电监测
          </el-button>-->
          <el-link href="scope3.row.ECGTestLink" target="_blank" type="success">心电检测{{scope3.row.ECGTestLink}}</el-link>
          <div></div>
          <el-link href="" target="_blank" type="success">24小时Holter</el-link>
          <div></div>
          <el-link href="" target="_blank" type="success">8导联</el-link>
          <div></div>
          <el-link href="" target="_blank" type="success">尿液分析</el-link>
          <div></div>
          <el-link href="" target="_blank" type="success">温度测量</el-link>
          <div></div>
          <el-link href="" target="_blank" type="success">血氧检测</el-link>
          <div></div>
          <el-link href="" target="_blank" type="success">血糖检测</el-link>
          <div></div>
          <el-link href="" target="_blank" type="success">血脂检测</el-link>
          <div></div>
          <el-link href="" target="_blank" type="success">血压检测</el-link>
          <div></div>
          <el-link href="" target="_blank" type="success">体脂检测</el-link>
          <div></div>
          <el-link href="" target="_blank" type="success">三合一检测仪</el-link>
          <div></div>
          <el-link href="" target="_blank" type="success">生化分析仪</el-link>

          <el-button slot="reference" size="mini" type="info">查看</el-button>
        </el-popover>
      </template>
<!--      <template v-slot:="scope3">
        <el-link href="scope3.row.url" target="_blank">
          查看
        </el-link>
      </template>-->
<!--      <el-button  size="mini" @click="$router.push({ name: 'healthDetailPage1' })" type="primary" style="margin-left: 16px;">
        查看
      </el-button>-->
<!--      <el-button size="mini" type="info" @click="dialogVisible = true">查看</el-button>

      <el-dialog
          title="请选择查询项目"
          :visible.sync="dialogVisible"
          width="30%"
          :before-close="handleClose">

        <el-button  size="mini" @click="$router.push({ name: 'healthDetailPage1' })" type="text" style="margin-left: 25px;font-size: 20px">
          心电监测
        </el-button>
        <div></div>
        <el-button  size="mini" @click="$router.push({ name: 'healthDetailPage1' })" type="text" style="margin-left: 25px;font-size: 20px">
          24小时Holter
        </el-button>

        <span slot="footer" class="dialog-footer">

    <el-button type="danger" @click="dialogVisible = false">关闭</el-button>
  </span>
      </el-dialog>-->

    </el-table-column>

    <el-table-column label="操作">

      <template v-slot:default="scope4">
        <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope4.$index, scope4.row)">删除</el-button>
      </template>
    </el-table-column>


<!--    <el-table-column
        align="right">
      <template slot="header" slot-scope="scope">
        <el-input
            v-model="search"
            size="mini"
            placeholder="输入关键字搜索"/>
      </template>
      <template slot-scope="scope">
        <el-button
            size="mini"
            @click="handleEdit(scope.$index, scope.row)">Edit</el-button>
        <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)">Delete</el-button>
      </template>
    </el-table-column>-->
  </el-table>
</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
//import引入的组件需要注入到对象中才能使用
components: {},
data() {
//这里存放数据
return {
  size: '',
  dialogVisible: false,
  drawer1: false,
  drawer2: false,

  tableData: [{
    userNum: '1',
    userAcc: '王小虎',
    userName:'王大虎',
    userInfo: '',
    userHeal:'',
    userGender:'男',
    userAge:'23',
    userBirth:'',
    userEthnic:'',
    userHomeTown:'',
    userEdu:'',
    userPolitical:'',
    userPhone:'11451419198',
    userMail:'2297597417@qq.com',
    userIDcard:'481013200910372321',
    userResidence:'',
    userRegisterType:'',
    userHouseOwner:'',
    userHouseID:'',
    userHeight:'175',
    userWeight:'70',
    userBlood:'B',
    userMarriage:'',
    userProfession:'',
    userEmployer:'',
    userSelfHealth:'',
    userMentalHealth:'',
    userAllergy:'',
    userSevereDisease:'',
    userChronicDisease:'',
    userInfectiousDisease:'',
    userGeneticDisease:'',
    userEndemicDisease:'',
    userPlague:'',
    userRareDisease:'',
    userFoodAllergy:'',
    userBloodTransfusion:'',
    userSurgicalHistory1:'',
    userSurgicalHistory2:'',
    userSurgicalHistory3:'',
    userFatherDisease:'',
    userMotherDisease:'',
    userBroSisDisease:'',
    userOffspringDisease:'',
    userExerciseFrequency:'',
    userExerciseWay:'',
    userExerciseTime:'',
    userEatingHabits:'',
    userSmoking:'',
    userSmokingAmount:'',
    userDrinking:'',
    userDrinkingAmount:'',
    userDrinkingFrequency:'',
    userSleeping:'',
    userSleepingAmount:'',
    userOccupationalDiseaseRisk:'',
    userRiskProtectiveMeasures:'',
    InTableData:[{
      ECGTestNumber:'1',
      TestItem:'心动过速',
      TestSuspicion:'0',
      TestConclusion:'正常',
    },
      {
        ECGTestNumber:'2',
        TestItem:'室性心动过速',
        TestSuspicion:'0',
        TestConclusion:'正常',
      }],
    ECGTestLink:'https://element.eleme.io',
  },
    {
      userNum: '2',
      userAcc: '李小虎',
      userName:'',

      userTest:'',
      userHeal:'',
    },
  ],
  search: '',
  input: '',

  draUserAcc:'王小虎',
  draUserName:'王大虎',

  ECGTableData:[{
    ECG001:'1',
    ECG002:'心动过速',
    ECG003:'0%',
    ECG004:'正常',
  }, {
    ECG001:'2',
    ECG002:'室性心动过速',
    ECG003:'0%',
    ECG004:'正常',
  }, {
    ECG001:'3',
    ECG002:'心动过缓',
    ECG003:'100%',
    ECG004:'偏高',
  },{
    ECG001:'4',
    ECG002:'交界性逸搏',
    ECG003:'30%',
    ECG004:'正常',
  }],
};
},
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {

  handleEdit(index, row) {
    console.log(index, row);
  },
  handleDelete(index, row) {
    console.log(index, row);
  },

  // eslint-disable-next-line no-unused-vars
  tableRowClassName({row, ECG004}) {
    if (ECG004 == '偏高') {
      return 'warning-row';
    }
   else return '';
  },

  openList() {
    this.$alert('这是一段内容',

        '请选择查看内容', {
      confirmButtonText: '关闭',
      callback: action => {
        this.$message({
          type: 'info',
          message: `action: ${ action }`
        });
      }
    });
  },

  handleClose(done) {
    this.$confirm('确认关闭？')
        // eslint-disable-next-line no-unused-vars
        .then(_ => {
          done();
        })
        // eslint-disable-next-line no-unused-vars
        .catch(_ => {});
  }

},
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {

},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.pageTop{
  font-size: 35px;
}
.drawerTxt{
  margin-left: 10px;
  margin-top: 15px;
  font-size: 25px;
}
.el-table .warning-row {
  background: oldlace;
}
</style>
